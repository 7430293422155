@import './anand.const.scss';

/*****************************************************************************/
/* Rhymes */
/*****************************************************************************/

.content-inline-rhymes {
	display: block;
	text-align: center;
	margin: 0 auto;
	margin-bottom: -0.8em;

	rhyme-line,
	.rhyme-line {
		display: inline-block;
		line-height: 1;

		&.rhyme-line-justified {
			display: block;
			text-align: justify;
			margin: 0 auto;

			&:after {
				content: '';
				display: inline-block;
				width: 100%;
			}

			&:first-of-type {
				margin-top: 0.6em;
			}
		}
	}

	rhyme-type {
		display: block;
		margin-top: -0.6em; //#2ea47f
	}

	rhyme-author,
	.rhyme-author {
		display: inline-block;
		text-align: right;
		width: 100%;
		margin-top: -0.5em;
	}

	&.locale-hi {
		// font-size: 0.9em;
		font-weight: bold;
	}
}

@media (min-width: $xlg-min-width) {
	.rhymes {
		& > .locale-hi {
			font-size: 2.9vw;
			.rhyme-line-justified {
				width: 55%;
			}
		}
	}
}

@media (max-width: $lg-max-width) {
	.rhymes {
		& > .locale-hi {
			font-size: 3.1vw;
			.rhyme-line-justified {
				width: 60%;
			}
		}
	}
}

@media (max-width: $md-max-width) {
	.rhymes {
		& > .locale-hi {
			font-size: 3.6vw;
			.rhyme-line-justified {
				width: 70%;
			}
		}
	}
}

@media (max-width: $sm-max-width) {
	.rhymes {
		& > .locale-hi {
			font-size: 4.1vw;
			.rhyme-line-justified {
				width: 80%;
			}
		}
	}
}

@media (max-width: $xxs-max-width) {
	.device-handheld {
		.content-inline-rhymes.locale-hi {
			font-size: 4.5vw;
			word-spacing: -0.1em;
		}
	}
}

.rhymes {
	color: white;
	text-align: center;
	padding: 2em 0em 0.5em 0;
	text-shadow: 0.1em 0.1em 0.2em blue;
	line-height: 1;
	font-weight: bold;

	.rhyme-line {
		&.rhyme-line-justified {
			margin-bottom: -0.6em;
		}
	}

	.locale-hi {
		color: white;
	}
}

.articles-div {
	.article-tile {
		.content-inline-rhymes.locale-hi {
			font-size: 1em;
			line-height: 1.4;
		}
	}
}

.notifications-div {
	img {
		width: 100%;
	}
}
/*****************************************************************************/
/*****************************************************************************/

.hidden-pass {
	input {
		-webkit-text-security: disc;
	}
}

.ck.ck-editor {
	border: 2px '#038b00' dashed !important; //#32b78d dashed !important;
}

.ck.ck-editor[lang='hi'] {
	@include locale-hi;
	font-size: 1.3em;
	text-align: justify;
	color: rgb(85, 85, 85);
	line-height: 2;

	p[style*='justify'] {
		text-indent: 3em;
	}
}

.ck.ck-balloon-panel {
	z-index: 10000;
}

.inline-edit-text,
.ql-container {
	p {
		margin: 0 0 0.85em;
		text-indent: 3em;

		&.ql-align-center {
			text-indent: 0;
		}
	}
}

.locale-en {
	.inline-edit-text,
	.ql-container {
		p {
			text-indent: 0;
		}
	}
}

.meaning {
	text-align: center;
	text-decoration: underline;
}

.next-line {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	width: 20vw;
	height: 3.5vw;
	max-width: 350px;
	max-height: 80px;
	margin: 0 auto;

	// &.end-content {
	// background-image: url($base-img-url + '/bg/dark/end-content.png');
	// }
}

.locale-hi {
	.next-line {
		padding: 1.1em;
	}
}

.locale-hi {
	ez {
		font-size: 0.7em;
	}
}

hide {
	visibility: hidden;
}

.mx-center {
	margin: 0 auto;
}

.page-center canvas {
	margin: 0 auto;
}

figure.image {
	margin: 0;
}

figure.image img {
	width: 100%;
}
